import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '@blueprint/services/api.service';
import { Quotation } from '@domain/models/quotation.model';
import { Project } from '@domain/models/project.model';
import { Contact } from '@domain/models/contact.model';
import { Address } from '@domain/models/address.model';
import { Client } from '@domain/models/client.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { DataService } from '@shared/services/data.service';
import { QuotationSignatureComponent } from './signature/quotation-signature.component';
import { Subscription } from 'rxjs';
import * as toastr from 'toastr';
import { SelectItem } from '@node_modules/primeng/components/common/selectitem';
import * as _ from 'lodash';
import * as uuid from 'uuid/v4';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { Activity } from '@domain/models/activity.model';
import { TransportTeam } from '@domain/models/transport-team.model';
import { Inventory } from '@domain/models/inventory.model';
import { InventoryItem } from '@domain/models/inventory-item.model';

@Component({
  selector: 'app-inventory-quotation',
  templateUrl: 'quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class InventoryQuotationComponent implements OnInit, OnDestroy {
  @ViewChild('clientSignature') clientSignature: QuotationSignatureComponent;
  @ViewChild('valuatorSignature') valuatorSignature: QuotationSignatureComponent;

  public project: Project;
  public client: Client;
  public quotation: Quotation;
  public pickupAddress: Address;
  public deliverAddress: Address;
  public isSubmitting: boolean;
  public numberFormatEuro: {};
  public numberFormat: {};
  public storageIsUsed: boolean;
  public showSealError: boolean;
  public amountOfNeededSeals: number;
  public sealPrice: number;
  public existingTeamOptions: TransportTeam[] = [];
  public teamOptions: SelectItem[] = [];
  public teamPrices: {label: string, price: number}[] = [];
  public relocationTeams: TransportTeam[] = [];
  public showStoragePrice: boolean = false;

  private storagePricePerWeekPerHalfContainer: number;
  private defaultTransportActivity: ProjectActivity;
  private removedTransportTeams: TransportTeam[] = [];
  private contact: Contact;
  private addresses: Address[];
  private mainAddress: Address;
  private halfContainerSize: number;
  private drivingHours: number = 0;
  private subscriptionProjectLoaded: Subscription;
  private moversCosts: number;
  private zipCodesLelystadAlmere: {
    start_zipcode: number,
    end_zipcode: number
  }[];
  private zipCodesDrontenZeewolde: {
    start_zipcode: number,
    end_zipcode: number
  }[];

  constructor(private syncService: SynchronisationService,
              private projectService: ProjectService,
              private dataService: DataService,
              private api: ApiService) {
    this.quotation = new Quotation({});
    this.contact = new Contact({});
    this.client = new Client({});
    this.project = new Project({});
    this.storageIsUsed = false;
    this.sealPrice = 2.5;
    this.showSealError = false;
    this.amountOfNeededSeals = 0;
    this.halfContainerSize = 15;
    this.storagePricePerWeekPerHalfContainer = 17.5;
    this.moversCosts = 0;
    this.zipCodesLelystadAlmere = [
      { start_zipcode: 8211, end_zipcode: 8245 },
      { start_zipcode: 1309, end_zipcode: 1363 }
    ];
    this.zipCodesDrontenZeewolde = [
      { start_zipcode: 8251, end_zipcode: 8256 },
      { start_zipcode: 2891, end_zipcode: 3899 }
    ];

    /** Format for invoice properties */
    this.numberFormat = { prefix: '', thousands: '.', decimal: ',', precision: 2 };
    this.numberFormatEuro = { prefix: '€ ', thousands: '.', decimal: ',', precision: 2 };

    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project: Project) => {
      this.project = project;

      this.teamOptions = [
        { label: 'Busje (ca 17 m3) met 1 verhuizer', value: 'Busje (ca 17 m3) met 1 verhuizer' },
        { label: 'Busje (ca 17 m3) met 2 verhuizers', value: 'Busje (ca 17 m3) met 2 verhuizers' },
        { label: 'Busje (ca 17 m3) met 3 verhuizers', value: 'Busje (ca 17 m3) met 3 verhuizers' },
        { label: 'Verhuisauto 40 m3 met 1 verhuizer', value: 'Verhuisauto 40 m3 met 1 verhuizer' },
        { label: 'Verhuisauto 40 m3 met 2 verhuizers', value: 'Verhuisauto 40 m3 met 2 verhuizers' },
        { label: 'Verhuisauto 40 m3 met 3 verhuizers', value: 'Verhuisauto 40 m3 met 3 verhuizers' },
        { label: 'Verhuisauto 40 m3 met 4 verhuizers', value: 'Verhuisauto 40 m3 met 4 verhuizers' }
      ];

      this.teamPrices = [
        { label: 'Busje (ca 17 m3) met 1 verhuizer', price: 75 },
        { label: 'Busje (ca 17 m3) met 2 verhuizers', price: 110 },
        { label: 'Busje (ca 17 m3) met 3 verhuizers', price: 150 },
        { label: 'Verhuisauto 40 m3 met 1 verhuizer', price: 100 },
        { label: 'Verhuisauto 40 m3 met 2 verhuizers', price: 135 },
        { label: 'Verhuisauto 40 m3 met 3 verhuizers', price: 175 },
        { label: 'Verhuisauto 40 m3 met 4 verhuizers', price: 210 }
      ];

      if (this.project.client) {
        this.client = this.project.client;
      } else {
        this.client = new Client({});
      }

      this.addresses = this.project.addresses;

      // Determine main address and pickup/deliver address
      for (const address of this.addresses) {
        switch (address.type) {
          case 'Hoofd adres':
            this.mainAddress = address;
            break;
          case 'Aflever adres':
            this.deliverAddress = address;
            break;
          case 'Afhaal adres':
            this.pickupAddress = address;
            break;
        }
      }

      if (this.project.quotations.length > 0) {
        this.quotation = this.project.quotations[0];
      } else {
        this.quotation = new Quotation({ project_id: this.project.id, base_price: null });
      }

      this.quotation.total_volume = this.projectService.calculateVolume('move') +  this.projectService.calculateVolume('storage');

      this.loadExistingTransportTeamActivities();
      this.checkForStorageItem();

      this.initForm();
    });

    this.initForm();
  }

  public getProjectStatus(): string {
    return Project.getStatusName(this.project.status);
  }

  public async updateRelocationTeam(index: number, label: string): Promise<void> {
    this.relocationTeams[index].transport_kind = this.teamOptions.find(option => option.value === label).label;
    this.relocationTeams[index].transport_price = this.teamPrices.find(price => price.label === label).price;

    await this.setBasePrice();
  }

  public async ngOnInit(): Promise<void> {
    this.project = this.projectService.getProject();

    if (this.project.client) {
      this.client = this.project.client;
    } else {
      this.client = new Client({});
    }

    if (this.project && this.project.quotations && this.project.quotations.length > 0) {
      this.quotation = this.project.quotations[0];
    } else {
      this.quotation = new Quotation({ project_id: this.project.id, base_price: null });
    }

    this.quotation.total_volume = this.projectService.calculateVolume('move') +  this.projectService.calculateVolume('storage');

    if (this.project.id) {
      await this.projectService.loadProject(this.project.id);
    }

    this.defaultTransportActivity = await this.getNewTransportActivity();

    await this.loadExistingTransportTeamActivities();
    await this.checkForStorageItem();
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    await this.updateTransportationFields();
  }

  public async addRelocationTeam(): Promise<void> {
    this.relocationTeams.push(_.cloneDeep(this.getNewDefaultRelocationTeam()));

    await this.setBasePrice();
  }

  public async removeRelocationTeam(index: number): Promise<void> {
    if (this.existingTeamOptions.find(option => option.id === this.relocationTeams[index].id)) {
      this.removedTransportTeams.push(this.relocationTeams[index]);
    }

    this.relocationTeams.splice(index, 1);

    await this.setBasePrice();
  }

  /**
   * Update the total prices
   */
  public async updateTotal(): Promise<void> {
    // Sum prices
    this.quotation.subtotal_price = 0;
    this.quotation.subtotal_price += this.quotation.base_price || 0;
    this.quotation.subtotal_price += this.quotation.packing_fragile_price || 0;
    this.quotation.subtotal_price += this.quotation.unpacking_fragile_price || 0;
    this.quotation.subtotal_price += this.quotation.packing_complete_price || 0;
    this.quotation.subtotal_price += this.quotation.assembly_price || 0;
    this.quotation.subtotal_price += this.quotation.packing_price || 0;
    this.quotation.subtotal_price += this.quotation.handyman_certificate_price || 0;
    this.quotation.subtotal_price += this.quotation.guarantee_certificate_price || 0; // 0% VAT
    this.quotation.subtotal_price += this.quotation.storage_handling_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_insurance_total_price || 0;
    this.quotation.subtotal_price += this.quotation.parking_waiver_price || 0;
    this.quotation.subtotal_price += this.quotation.floor_surcharge || 0;
    this.quotation.subtotal_price += this.quotation.elevator_surcharge || 0;
    this.quotation.subtotal_price += this.quotation.piano_safe_surcharge || 0;
    this.quotation.subtotal_price += this.quotation.remove_lights_curtains_price || 0;
    this.quotation.subtotal_price += this.quotation.washing_machine_brace_price || 0;
    this.quotation.subtotal_price += this.quotation.piano_grand_piano_price || 0;
    this.quotation.subtotal_price += this.quotation.waterbed_relocation_price || 0;

    this.quotation.subtotal_price += this.quotation.custom_option_1_price || 0;
    this.quotation.subtotal_price += this.quotation.custom_option_2_price || 0;
    this.quotation.subtotal_price += this.quotation.custom_option_3_price || 0;

    // Calculate vat (exclude guarantee certificate, handyman certificate and storage insurance)
    const calcVat = (amount) => {
      return amount ? Math.round((amount * 100) * 0.21) / 100 : 0;
    };
    this.quotation.vat_price = 0;
    this.quotation.vat_price += calcVat(this.quotation.base_price);
    this.quotation.vat_price += calcVat(this.quotation.packing_fragile_price);
    this.quotation.vat_price += calcVat(this.quotation.unpacking_fragile_price);
    this.quotation.vat_price += calcVat(this.quotation.packing_complete_price);
    this.quotation.vat_price += calcVat(this.quotation.assembly_price);
    this.quotation.vat_price += calcVat(this.quotation.packing_price);
    this.quotation.vat_price += calcVat(this.quotation.storage_handling_price);
    this.quotation.vat_price += calcVat(this.quotation.parking_waiver_price);
    this.quotation.vat_price += calcVat(this.quotation.floor_surcharge);
    this.quotation.vat_price += calcVat(this.quotation.elevator_surcharge);
    this.quotation.vat_price += calcVat(this.quotation.piano_safe_surcharge);
    this.quotation.vat_price += calcVat(this.quotation.remove_lights_curtains_price);
    this.quotation.vat_price += calcVat(this.quotation.washing_machine_brace_price);
    this.quotation.vat_price += calcVat(this.quotation.piano_grand_piano_price);
    this.quotation.vat_price += calcVat(this.quotation.waterbed_relocation_price);

    this.quotation.vat_price += calcVat(this.quotation.custom_option_1_price);
    this.quotation.vat_price += calcVat(this.quotation.custom_option_2_price);
    this.quotation.vat_price += calcVat(this.quotation.custom_option_3_price);

    this.quotation.total_price = (
      this.quotation.subtotal_price +
      this.quotation.vat_price
    );
  }

  public async submitQuotation(status: string): Promise<void> {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;

    switch (status) {
      case 'pending':
        this.quotation.status = 'queued';
        break;
      case 'booked':
        this.quotation.status = 'quotation_sent';
        break;
      default:
        break;
    }

    await this.updateTransportationFields();

    // Update project status
    this.project.status = status;
    await this.projectService.saveProject();

    toastr.info('Offerte wordt verstuurd..', 'Synchronisatie');

    // Trigger sync to backend
    const result = await this.syncService.syncToBackend().catch(_ => {
      toastr.error('Offerte kan nog niet verstuurd worden, dit wordt later uitgevoerd zodra er weer verbinding is', 'Synchronisatie');
      this.syncService.shouldSync = true;
    });

    if (result) {
      toastr.success('Offerte succesvol verstuurd', 'Synchronisatie');

      // Reload project
      await this.projectService.loadProject(this.project.id);
    }

    this.isSubmitting = false;
  }

  public async resetCustomBasePrice(): Promise<void> {
    this.quotation.manually_changed_base_price = false;

    await this.updateBasePrice();
  }

  public updatedBasePriceManually(): void {
    this.quotation.manually_changed_base_price = true;
  }

  private async getTransportActivities(): Promise<ProjectActivity[]> {
    const activities: ProjectActivity[] = [];
    for (const relocationTeam of this.relocationTeams) {
      activities.push(await this.getNewTransportActivity(relocationTeam.id, relocationTeam.transport_kind, relocationTeam.transport_price, relocationTeam.transport_hours));
    }

    return activities;
  }

  private async loadExistingTransportTeamActivities(): Promise<void> {
    const projectActivities = await ProjectActivity.query
      .where('project_id')
      .equals(this.project.id)
      .toArray();

    for (const activity of projectActivities) {
      await activity.init();
    }

    this.existingTeamOptions = [];
    projectActivities.filter(item => item.activity.project_type === 'transport').forEach((activity: ProjectActivity) => {
      this.existingTeamOptions.push(activity);
    });

    if (this.existingTeamOptions && this.existingTeamOptions.length > 0) {
      this.relocationTeams = _.cloneDeep(this.existingTeamOptions);
    } else {
      this.relocationTeams = _.cloneDeep([this.getNewDefaultRelocationTeam()]);
    }

    await this.setBasePrice();
  }

  private async updateTransportationFields(): Promise<void> {
    if (this.removedTransportTeams && this.removedTransportTeams.length > 0) {
      const removeActivities = [];
      for (const activity of this.removedTransportTeams) {
        removeActivities.push(this.existingTeamOptions.find(option => option.id === activity.id));
      }

      await this.projectService.deleteActivities(removeActivities);
    }

    const updateActivities = await this.getTransportActivities();
    await this.projectService.saveActivities(updateActivities);
    await this.saveQuotation();
  }

  private getNewDefaultRelocationTeam(): TransportTeam {
    return {
      id: uuid(),
      transport_kind: this.teamOptions[0].label,
      transport_price: this.teamPrices[0].price,
      transport_hours: 1
    };
  }

  private async getNewTransportActivity(id: string = null, transport_kind: string = '', transport_price: number = 0, transport_hours: number = 0): Promise<ProjectActivity> {
    if (!this.defaultTransportActivity) {
      const transportActivities = await Activity.query
        .where('project_type')
        .equals('transport')
        .toArray();

      let transportActivity = transportActivities[0];
      if (transportActivity) {
        await transportActivity.init();
      }

      const projectActivity = new ProjectActivity({
        activity_id: transportActivity ? transportActivity.id : null,
        project_id: this.project.id,
        transport_kind: transport_kind,
        transport_price: transport_price,
        transport_hours: transport_hours,
      });

      await projectActivity.init();

      this.defaultTransportActivity = projectActivity;

      return projectActivity;
    }

    const activity = _.cloneDeep(this.defaultTransportActivity);
    activity.id = id ? id : uuid();
    activity.transport_kind = transport_kind;
    activity.transport_price = transport_price;
    activity.transport_hours = transport_hours;

    return activity;
  }

  private async calculateHandlingCosts(): Promise<void> {
    if (this.showStoragePrice) {
      // const amountOfHalfContainersTotalVolume = Math.ceil(+this.quotation.total_volume / +this.halfContainerSize);
      const amountOfHalfContainersStorageVolume = Math.ceil(+this.projectService.calculateVolume('storage') / +this.halfContainerSize);

      let handlingCosts = 0;
      let priceToggle = true;
      // for (let i = 0; i < +amountOfHalfContainersTotalVolume; i++) {
      for (let i = 0; i < +amountOfHalfContainersStorageVolume; i++) {
        if (priceToggle) {
          handlingCosts += 55;
        } else {
          handlingCosts += 52.5;
        }

        priceToggle = !priceToggle;
      }

      this.quotation.storage_handling_price = +handlingCosts / 121 * 100;
      this.quotation.storage_week_total_price = +amountOfHalfContainersStorageVolume * this.storagePricePerWeekPerHalfContainer;
    } else {
      this.quotation.storage_handling_price = 0;
      this.quotation.storage_week_total_price = 0;
    }

    await this.updateTotal();
  }

  public async calculateDrivingHours(): Promise<void> {
    if (!Number(this.quotation.estimated_distance_km)) {
      this.quotation.estimated_distance_km = 0;
    }

    this.drivingHours = this.getDistance() / 80;

    await this.setBasePrice();
  }

  public async setBasePrice(): Promise<void> {
    let relocationTeamsPrice: number = 0;
    if (this.relocationTeams && this.relocationTeams.length > 0) {
      this.relocationTeams.forEach((team: TransportTeam) => {
        relocationTeamsPrice += +team.transport_price * (team.transport_hours + this.drivingHours);
      });
    }

    if (!this.quotation.manually_changed_base_price) {
      this.quotation.base_price = (relocationTeamsPrice / 121 * 100);
    }

    await this.updateTotal();
  }

  public showClientSignatureForm(): void {
    this.clientSignature.showForm();
  }

  public showValuatorSignatureForm(): void {
    this.valuatorSignature.showForm();
  }

  private getDistance(): number {
    if ((this.quotation.distance_km !== this.quotation.estimated_distance_km) || this.quotation.manually_changed_base_price) {
      return +this.quotation.estimated_distance_km;
    }

    return +this.quotation.distance_km;
  }

  private async checkForStorageItem(): Promise<void> {
    if (this.project && this.project['inventories']) {
      this.project['inventories'].forEach((inventory: Inventory) => {
        if (inventory && inventory.items) {
          inventory.items.forEach((inventoryItem: InventoryItem) => {
            if (inventoryItem.amount > 0 && inventoryItem.inventory_type === 'storage') {
              this.showStoragePrice = true;
              return;
            }
          });

          if (this.showStoragePrice) {
            return;
          }
        }
      });

      await this.calculateHandlingCosts();
    } else {
      await this.calculateHandlingCosts();
    }
  }

  /**
   * Initialise the form
   */
  private async initForm(): Promise<void> {
    await this.checkForStorageItem();
    await this.calculateSpecialtiesCosts();
    await this.updateDistance();
    await this.updateBasePrice();
    await this.setBasePrice();
    await this.updateTotal();
  }

  /**
   * Updates the distance in KM's
   */
  private async updateDistance(): Promise<void> {
    if (!this.deliverAddress || !this.pickupAddress) {
      return;
    }

    const result = await this.api
      .post('/address/distance', {
        from: {
          street: this.pickupAddress.street,
          housenumber: this.pickupAddress.housenumber,
          zipcode: this.pickupAddress.zipcode,
          city: this.pickupAddress.city,
          country: this.pickupAddress.country
        },
        to: {
          street: this.deliverAddress.street,
          housenumber: this.deliverAddress.housenumber,
          zipcode: this.deliverAddress.zipcode,
          city: this.deliverAddress.city,
          country: this.deliverAddress.country
        }
      })
      .toPromise();

    if (result && result.data && result.data > 0) {
      this.quotation.distance = +result.data;
    } else {
      toastr.error('Het aantal kilometers kan niet worden berekend.', 'Kilometers');
    }

    this.quotation.distance_km = (+this.quotation.distance / 1000) || 0;
    this.quotation.estimated_distance_km = (+this.quotation.estimated_distance > 0 && +this.quotation.estimated_distance !== this.quotation.distance_km) ? +this.quotation.estimated_distance / 1000 : this.quotation.distance_km;
  }

  /**
   * Updates base price based on volume and distance
   */
  private async updateBasePrice(): Promise<void> {
    if (this.quotation.manually_changed_base_price) {
      return;
    }

    await this.calculateDrivingHours();
  }

  /**
   * Determine all specialties costs
   */
  private async calculateSpecialtiesCosts(): Promise<void> {
    this.quotation.packing_fragile_price = await this.project.getSpecialtyTotalPrice('Inpakken');
    this.quotation.unpacking_fragile_price = await this.project.getSpecialtyTotalPrice('Uitpakken');
    this.quotation.assembly_price = await this.project.getSpecialtyTotalPrice('(De)montage');
    this.quotation.remove_lights_curtains_price = await this.project.getSpecialtyTotalPrice('Afhalen lampen / gordijnrails');
    this.quotation.waterbed_relocation_price = await this.project.getSpecialtyTotalPrice('Waterbed verhuizing');
    this.quotation.piano_grand_piano_price = await this.project.getSpecialtyTotalPrice('Piano / vleugel verhuizing');

    await this.updateTotal();
  }

  private async saveQuotation(): Promise<void> {
    this.quotation.estimated_distance = this.quotation.estimated_distance_km > 0 ? this.quotation.estimated_distance_km * 1000 : null;

    await this.projectService.saveQuotation(this.quotation);
  }
}
